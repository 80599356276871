import React from 'react'
import "./Home.scss";
import errorImage from '../../assets/images/404.jpg'
import CreateButton from '../../components/createButton/CreateButton';
import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function ErrorPage() {
    const navigate = useNavigate();
    return (
        <div className='d-flex flex-column gap-12 mb-11'>
            <div>
                <header>
                    <div className='min-vh-65 d-flex justify-content-center align-items-center position-relative'>
                        <div>
                           
                            <p
                                style={{ letterSpacing: "2px" }}
                                className='text-light p-2 rounded my-4  text-center'
                            >
                                Sorry, the page you are looking for could not be found, <br></br> but there is a lot more to see on the home page.
                            </p>
                                <h1
                                    style={{ fontFamily: "open-sans, sans-serif", letterSpacing: "50px" }}
                                className='fs-22 justify-content-center  align-items-center fw-bold text-light text-center grade-text opacity-50'
                                >
                                    404
                                </h1>
                            <div className=''>
                        
                                </div>
                        </div>
                    </div>
                    <Row lg={1} md={1} sm={1} xs={1} className='d-flex justify-content-center'>
                        <Col className='d-flex justify-content-center mb-3'>
                            <Button onClick={() => { navigate(`/`, { replace: true }) }} className='fw-bold px-3 rounded-5' variant='light'>
                                CFI Home
                            </Button>
                        </Col>
                        <Col className='d-flex justify-content-center'>
                            <Button onClick={() => {navigate(-1)}} className='fw-bold px-4 rounded-5' variant='light'>
                               Go back
                            </Button>
                        </Col>
                    </Row>
                    {/* <video type="video/mp4" style={{ width: "100vw", height: "100vh", objectFit: "cover" }} autoPlay muted loop className='position-fixed z-n3 top-0   brightness-100' src={homeImg} alt="" /> */}
                    <img style={{ width: "100vw", height: "100vh", objectFit: "cover" }} className='position-fixed z-n3 top-0   brightness-100' src={errorImage} alt="error" />
                </header>
            </div>

        </div>
    )
}

export default ErrorPage

import React from 'react';
import { AiOutlinePlusCircle } from 'react-icons/ai';

const CreateButton = ({ text, className = null, icon = null, iconNeeded = true, ...rest }) => {
    return (
        <div {...rest} className={className ? className : `px-3 py-2 rounded btn btn-dark bg-gradient border border-0`}>
            <p className="d-flex gap-1 align-items-center m-0 justify-content-center">
                {
                    iconNeeded ?
                        icon
                            ?
                            icon
                            :
                            <AiOutlinePlusCircle />
                        :
                        ''
                }
                <span>{text}</span>
            </p>
        </div>
    );
};

export default CreateButton;
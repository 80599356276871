import React, { lazy } from 'react';
import { useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import ChatLogin from "./components/login/index";

/**-Pages-**/
import MainLayout from './layout/mainLayout/MainLayout';
import Authentication from './layout/authentication/Authentication';
import ReactSuspense from './components/reactSuspense/ReactSuspense';
import Topics2 from './pages/topics/Topics2';
import Downtime from './components/downtime/Downtime';
import Jobs from './pages/jobs/Jobs';
import ErrorPage from "./pages/errorPage/ErrorPage";
const Chat = lazy(()=> import('./pages/chat/Chat'));
const ChatLayout = lazy(()=> import('./layout/chatLayout/ChatLayout'));
const Donate = lazy(() => import('./pages/donate/Donate'));
const DonationPage = lazy(()=> import('./pages/donation/DonationPage'));
const Partnership = lazy(() => import('./pages/partnership/Partnership'));
const PublicProfile = lazy(()=> import('./pages/publicProfile/PublicProfile'));
const ComingSoon = lazy(() => import('./pages/comingSoon/ComingSoon'));
const SettingsPage = lazy(() => import('./pages/accountSettings/SettingsPage'));
const AwaitingVerification = lazy(() => import('./pages/awaitingVerification/AwaitingVerification'));
const VerifyAccount = lazy(() => import('./pages/verifyAccount/VerifyAccount'));
const ScrollToTop = lazy(() => import('./components/scrollToTop/ScrollToTop'))
const SearchLayout = lazy(() => import('./layout/searchLayout/SearchLayout'))
const SearchAll = lazy(() => import('./pages/searchAll/SearchAll'))
const SearchGroup = lazy(() => import('./components/SearchGroup'))
const SearchPeople = lazy(() => import('./pages/searchPeople/SearchPeople'))
const SearchProject = lazy(() => import('./pages/searchProject/SearchProject'))
const SearchStreming = lazy(() => import('./components/SearchStreming'))
const AddProject = lazy(() => import('./pages/AddProject'))
const AllProjects = lazy(() => import('./pages/allProjects/AllProjects'))
const AllPeople = lazy(() => import('./pages/allPeople/AllPeople'))
const Login = lazy(() => import('./pages/Login'))
const Profile = lazy(() => import('./pages/profile/Profile'))
const Event = lazy(() => import('./pages/event/Event'))
const Project = lazy(() => import('./pages/project/Project'))
const Register = lazy(() => import('./pages/Register'))
const EventList = lazy(() => import('./pages/event/EventList'))
const Terms = lazy(() => import('./pages/legal/Terms'))
const Privacy = lazy(() => import('./pages/legal/Privacy'))
const Cookies = lazy(() => import('./pages/legal/Cookies'))
const Group = lazy(() => import('./pages/group/Group'))
const Home = lazy(() => import('./pages/home/Home'))

function App() {
  const [user, setUser] = useState(null);
  const [secret, setSecret] = useState(null);
  const isAuth = Boolean(user) && Boolean(secret);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route element={<ScrollToTop />}>
            <Route element={<MainLayout />}>
              <Route element={<ReactSuspense />}>
                <Route path="/register" element={<Register />} />
                <Route path="/awaiting-verification" element={<AwaitingVerification />} />
                <Route path="/verify/account/:id" element={<VerifyAccount />} />
                <Route path="/" element={<Home />} />
                  <Route path="/page-not-found" element={<ErrorPage />} />
                <Route path="/login" element={<Login />} />
                <Route path="/terms-of-use" element={<Terms />} />
                <Route path="/privacy-policy" element={<Privacy />} />
                <Route path="/cookies-policy" element={<Cookies />} />
                <Route path="/coming-soon" element={<ComingSoon />} />
                <Route path="donate" element={<Donate />} />
                <Route path="partnership" element={<Partnership />} />
                <Route path="/public-profile/:userId" element={<PublicProfile />} />
                <Route element={<Authentication />}>
                  <Route path='/search' element={<SearchLayout />}>
                    <Route path="all" element={<SearchAll />} />
                    <Route path="profiles" element={<SearchPeople />} />
                    <Route path="projects" element={<SearchProject />} />
                    <Route path="videos" element={<SearchStreming />} />
                    <Route path="group" element={<SearchGroup />} />
                    <Route path="event" element={<EventList />} />
                  </Route>
                  <Route path="/profile/:userId" element={<Profile />} />
                  <Route path="/profile/:userId/create-project" element={<AddProject />} />
                  <Route path='/settings/:userId' element={<SettingsPage />} />
                  <Route path="/project/:projectId" element={<Project />} />
                  {/* <Route path='/settings' element={<SettingsPage />} /> */}
                  <Route path="/group" element={<Group />} />
                  <Route path="/topics" element={<Topics2 />} />
                  <Route path="/projects" element={<AllProjects />} />
                  <Route path="/profiles" element={<AllPeople />} />
                  <Route path="/jobs" element={<Jobs />} />
                  <Route path="/event" element={<Event />} />
                  <Route path='downtime' element={<Downtime />} />
                  <Route path="payment" element={<DonationPage />} />
                  <Route path="/event/:eventId" element={<Event />} />
                  <Route path="/chatlogin" element={isAuth ? (<Navigate to="/chat" />) : (<ChatLogin setUser={setUser} setSecret={setSecret} />)} />
                  <Route path="/chat" element={<ChatLayout />}>
                    <Route path=":id" element={<Chat />} />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;

import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactDOM from "react-dom/client";
import "./index.scss";
import "./assets/css/global.scss";
import "./assets/css/custom.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-step-progress-bar/styles.css";
import store from "./store/store";
import { setupListeners } from "@reduxjs/toolkit/query";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import ru from "javascript-time-ago/locale/ru.json";
import kc from "./utils/keycloak";

/** Providers */
import { Provider } from "react-redux";
import {
  ReactKeycloakProvider as KeycloakProvider,
  useKeycloak,
} from "@react-keycloak/web";
import {
  LivepeerConfig,
  createReactClient,
  studioProvider,
} from "@livepeer/react";
import { useGetLoggedInUserInfoQuery } from "./features/auth/authSlice";

const livepeerClient = createReactClient({
  provider: studioProvider({
    apiKey: process.env.REACT_APP_LIVEPEER,
  }),
});

const livepeerTheme = {
  colors: {
    accent: "rgb(0, 145, 255)",
    containerBorderColor: "rgba(0, 145, 255, 0.9)",
  },
  fonts: {
    display: "Inter",
  },
};

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ru);

/** Keycloak configuration and helpers */
const kcInitOptions = {
  onLoad: "check-sso",
  silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html",
};

const CleanUrlComponent = () => {
  const { keycloak } = useKeycloak();
  const { refetch: refetchUserInfo } = useGetLoggedInUserInfoQuery();

  useEffect(() => {
    if (!keycloak) return;

    if (keycloak.authenticated) {
      refetchUserInfo();
      localStorage.setItem("token", keycloak.token);

      // Clean URL without query params
      const cleanUrl = window.location.href.split("?")[0];
      window.history.replaceState({}, document.title, cleanUrl);

      // Token refresh handling
      const refreshInterval = setInterval(() => {
        if (keycloak.isTokenExpired(5)) {
          keycloak
            .updateToken(60)
            .then((refreshed) => {
              if (refreshed) {
                localStorage.setItem("token", keycloak.token);
              }
            })
            .catch(() => {
              console.error("Token refresh failed, logging out");
              localStorage.removeItem("token");
              keycloak.logout();
            });
        }
      }, 60000); // Check every 1 min

      return () => clearInterval(refreshInterval);
    } else {
      localStorage.removeItem("token");
    }
  }, [keycloak, keycloak?.authenticated, refetchUserInfo]);

  return null;
};

setupListeners(store.dispatch);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <KeycloakProvider
    authClient={kc}
    initOptions={kcInitOptions}
    onEvent={(event) => {
      if (event === "onAuthSuccess") {
        localStorage.setItem("token", kc.token);
      }
    }}
  >
    <Provider store={store}>
      <LivepeerConfig client={livepeerClient} theme={livepeerTheme}>
        <App />
        <CleanUrlComponent />
      </LivepeerConfig>
    </Provider>
  </KeycloakProvider>
);

reportWebVitals();
